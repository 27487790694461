import React from 'react';

const MyReactComponent = () => {
  return (
    <div className="wrapper" style={{ position: 'relative', zIndex: 1, display: 'inline-block', width: '100vw' }}>
      <iframe
        src="https://legallaw.streamlit.app/?embed=true"
        style={{ display: 'block', background: '#ffffff', border: 'none', height: '125vh', width: '100%',overflow:'auto' }}
      >
        <p>Your browser doesn't support iframes</p>
      </iframe>
    </div>
  );
};

export default MyReactComponent;
