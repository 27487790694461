import React, {select, useState, useEffect, useRef} from 'react';
import "../../styles/index.css"
import { useAuth } from 'contexts/authContext';
import {useNavigate} from "react-router-dom"
import CustomDropdown from 'Homepage/dropdown/drop';
function Navbar() {
	const navRef = useRef();
	const [error,setError]=React.useState("")
	const {currentUser,logout}=useAuth()
	const navigate=useNavigate()
	async function handleLogout(){
		setError("")
		try{
		  await logout()
		  navigate('/')
		  
		}catch{
		  setError("Failed to logout")
		}
	  }

	const showNavbar = () => {
		navRef.current.classList.toggle(
			"responsive_nav"
		);
	};
	return (
		<div>
		<div className='headerhh'>
			<h2>LLG.</h2>
			<button className="bb" onClick={handleLogout}>Logout</button>

		</div>
		</div>
	);
}


export default Navbar;
